import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import certification1 from '../img/AB-seal-horz.svg.svg';
import googlePartners from '../img/googlePartners.png';
import { homeScroll } from '../smoothScroll';
import indian from '../img/indian.png';
import LogContext from '../context/LogContext';
export default function Footer() {
    const myContext = useContext(LogContext);
    const [currencyType, setCurrencyType] = useState('INR');
    const setConverterRatio = () => {
        const api = "https://api.exchangerate-api.com/v4/latest/USD";
        function getResults() {
            fetch(`${api}`)
                .then(currency => {
                    return currency.json();
                }).then(currency => {
                    let fromRate = currency.rates['INR'];
                    let toRate = currency.rates[currencyType];
                    localStorage.setItem('currencyRatio', (toRate / fromRate));
                    myContext.updateCurrency(currencyType, (toRate / fromRate));
                });
        }
        getResults()
    }
    useEffect(() => {
        setConverterRatio();
        localStorage.setItem('currencyType', currencyType);
    }, [currencyType])
    return (
        <>
            <div className="container-fluid footer py-5" style={{ fontsize: '14px' }}>
                <div className="container py">
                    <div className="row g-5">
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item1 d-flex flex-column">
                                <h4 className="mb-4 text-white">Get In Touch</h4>
                                <Link href="" style={{ textDecorationLine: 'none', fontSize: '14px' }}><i className="fas fa-home me-2" ></i>B-1, Soldier Tower, Commercial <span style={{ marginLeft: '27px' }}> Complex, Vikas Puri, New Delhi-<span style={{ marginLeft: '27px' }}>India,110018</span></span></Link>
                                <Link href="" style={{ textDecorationLine: 'none', fontSize: '14px' }}><i className="fas fa-envelope me-2"></i> sales@theautodial.com</Link>
                                <a href="tel: +91 8826450057" style={{ textDecorationLine: 'none', fontSize: '14px' }}><i className="fas fa-phone me-2"></i>India <img className='flags' src={indian} alt="" /><span className='phone-number'>+91 8826450057</span></a>

                                <div className="mt-5 d-flex align-items-center">
                                    <i className="fas fa-share fa-2x text-white me-2"></i>
                                    {/* <Link className="btn-square btn btn-primary rounded-circle mx-1" to="https://www.facebook.com/profile.php?id=61552474081957"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn-square btn btn-primary rounded-circle mx-1" to=""><i className="fab fa-twitter"></i></Link>
                                <Link className="btn-square btn btn-primary rounded-circle mx-1" to="https://www.instagram.com/jdtechnicalsolution?igsh=c3pwdmwxZW1sOXN3"><i className="fab fa-instagram"></i></Link>
                                <Link className="btn-square btn btn-primary rounded-circle mx-1" to=""><i className="fab fa-linkedin-in"></i></Link> */}
                                    <Link className="btn-square btn  rounded-circle mx-1" style={{ background: '#25D366' }} to="https://wa.me/message/BYVTYYBY34JFM1"><i className="fab fa-whatsapp btn-whatsapp"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column">
                                <h4 className="mb-4 text-white">Company</h4>
                                <div onClick={() => { homeScroll('about') }}><Link to="/"><i className="fas fa-angle-right me-2"></i> Home</Link></div>
                                <div onClick={() => { homeScroll('plan') }}><Link to="/pricing"><i className="fas fa-angle-right me-2"></i> Pricing</Link></div>
                                <div onClick={() => { homeScroll('testimonial') }}><Link to="/"><i className="fas fa-angle-right me-2"></i> Testimonial</Link></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column">
                                <h4 className="mb-4 text-white">Support</h4>
                                <div onClick={() => { homeScroll('form') }}><Link to="/contact"><i className="fas fa-angle-right me-2"></i> Contact</Link></div>
                                <div onClick={() => { homeScroll('about') }} ><Link to="/SLA"><i className="fas fa-angle-right me-2"></i> SLA</Link></div>
                                <div onClick={() => { homeScroll('about') }}><Link to="/privacyPolicy"><i className="fas fa-angle-right me-2"></i> Privacy Policy</Link></div>
                                <div onClick={() => { homeScroll('about') }}><Link to="/t&c"><i className="fas fa-angle-right me-2"></i> Terms and Conditions</Link></div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item">
                                <div className="row gy-3 gx-2 mb-4">
                                    <div className="col-xl-6">
                                        <form>
                                            <div className="form-floating">
                                                <select className="form-select border" id="select1">
                                                    <option value="3">English</option>
                                                </select>
                                                <label htmlFor="select1">English</label>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-xl-6">
                                        <form>
                                            <div className="form-floating">
                                                <select className="form-select  border" id="select1" onChange={(e) => setCurrencyType(e.target.value)}>
                                                    <option value="INR">INR</option>
                                                    <option value="USD">USD</option>
                                                    <option value="EUR">EUR</option>

                                                </select>
                                                <label htmlFor="select1">$</label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <h4 className="text-white mb-3">Payments</h4>
                                <div className="footer-bank-card">
                                    <Link to="/" className="text-white me-2"><i className="fab fa-cc-amex fa-2x"></i></Link>
                                    <Link to="/" className="text-white me-2"><i className="fab fa-cc-visa fa-2x"></i></Link>
                                    <Link to="/" className="text-white me-2"><i className="fas fa-credit-card fa-2x"></i></Link>
                                    <Link to="/" className="text-white me-2"><i className="fab fa-cc-mastercard fa-2x"></i></Link>
                                    <Link to="/" className="text-white me-2"><i className="fab fa-cc-paypal fa-2x"></i></Link>
                                    <Link to="/" className="text-white"><i className="fab fa-cc-discover fa-2x"></i></Link>
                                </div>
                                <div className='footer-bank-card my-3'>
                                    <img className=' social-links ms-3' src={certification1} alt="" style={{ width: '35%' }} />&nbsp;&nbsp;&nbsp;
                                    <img src={googlePartners} alt="" style={{ width: '35%' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0 copyright text-body bg-white">
                <div className="container p-0 mt-0">
                    <div className="row g-4 align-items-center mt-0 p-0">
                        <div className="col text-center mt-0 text-primary mb-md-0 p-0">
                            <span className='text-primary'>Copyright </span><i className="fas fa-copyright  me-2"></i>2024 | All Rights Reserved by JD Technical Solution.
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
